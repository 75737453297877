/* eslint-disable no-console */
import Constants from "../common/Constants";
import { http } from "../plugin/http2-common";

class UploadFileService {
  upload(file, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    return http.post("/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }

  uploadSurvey(file, id, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    return http.post(`/api/survey/upload/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }

  uploadWithFileNamePrefix(file, filenamePrefix, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file, filenamePrefix + "_" + file.name);
    return http.post("/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }

  uploadLargeFileWithFileNamePrefix(file, filenamePrefix, type, onUploadProgress) {
    let formData = new FormData();
    formData.append("file", file, filenamePrefix + "_" + file.name);
    return http.post("/api/upload-large-file?type=" + type, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  }

  getFiles() {
    return http.get("api/files");
  }

  checkImgBeforeUpload(file) {
    return new Promise((resolve, reject) => {
      try {
        var suffix = file['type'];
        if (suffix !== 'image/png' && suffix !== 'image/jpeg') {
          reject(Constants.LENS_SLIDER_TYPE_ERROR);
        }else if(file.size > Constants.LENS_SLIDER_MAX_SIZE_UPLOAD) {
          reject(Constants.LENS_SLIDER_MAX_SIZE_ERROR);
        }else {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            var image = new Image();
            image.src = reader.result;
            image.onload = function() {
              if(image.width > Constants.LENS_SLIDER_MAX_WIDTH_UPLOAD || image.height > Constants.LENS_SLIDER_MAX_HEIGHT_UPLOAD) {
                reject(Constants.LENS_SLIDER_RESOLUTION_ERROR);
              }else {
                resolve(true);
              }
            };
          };
          reader.onerror = function (error) {
            reject(error);
          };
        }
      } catch (error) {
        reject(error);
      }
    })
  }

  checkImageCategoryBeforeUpload(file, type) {
    return new Promise((resolve, reject) => {
      try {
        let size = 0;
        let width = 0;
        let height = 0;
        if(type === 'category') {
          size = Constants.CATEGORY_MAX_SIZE_UPLOAD;
          width = Constants.CATEGORY_MAX_WIDTH_UPLOAD;
          height = Constants.CATEGORY_MAX_HEIGHT_UPLOAD;
        }
        if(type === 'image') {
          size = Constants.IMAGE_MAX_SIZE_UPLOAD;
          width = Constants.IMAGE_MAX_WIDTH_UPLOAD;
          height = Constants.IMAGE_MAX_HEIGHT_UPLOAD;
        }
        if(type === 'icon') {
          size = Constants.ICON_MAX_SIZE_UPLOAD;
          width = Constants.ICON_MAX_WIDTH_UPLOAD;
          height = Constants.ICON_MAX_HEIGHT_UPLOAD;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          var image = new Image();
          image.src = reader.result;
          image.onload = function() {
            if(image.width > width || image.height > height || file.size > size) {
              console.log(image.width, width);
              console.log(image.height, height);
              console.log(file.size, size);
              reject(false);
            }else {
              resolve(true);
            }
          };
        };
        reader.onerror = function (error) {
          reject(error);
        };
      } catch (error) {
        reject(error);
      }
    })
  }

  checkQuestionaireQ3ImageBeforeUpload(file) {
    return new Promise((resolve, reject) => {
      try {
        let size = Constants.QUESTIONAIRE_IMAGE_MAX_SIZE_UPLOAD;
        let width = Constants.QUESTIONAIRE_IMAGE_MAX_WIDTH_UPLOAD;
        let height = Constants.QUESTIONAIRE_IMAGE_MAX_HEIGHT_UPLOAD;
        
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          var image = new Image();
          image.src = reader.result;
          image.onload = function() {
            if(image.width > width || image.height > height || file.size > size) {
              console.log(image.width, width);
              console.log(image.height, height);
              console.log(file.size, size);
              reject(false);
            }else {
              resolve(true);
            }
          };
        };
        reader.onerror = function (error) {
          reject(error);
        };
      } catch (error) {
        reject(error);
      }
    })
  }
}

export default new UploadFileService();
