/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// import _ from 'lodash';
import { isEmpty } from "../../common/CommonUtils";
import { http } from "../../plugin/http2-common";
import UploadFileService from "../UploadFileService";
import AfterSalesEmailMock from "./mock/AfterSalesEmailMock";
import OpticianEmailMock from "./mock/OpticianEmailMock";

const EMAIL_UPDATE_API = "/email/update/";
const EMAIL_GET_API = "/email/list/";
const OPTICIAN_EMAIL_TYPE = 1;
const AFTER_SALES_EMAIL_TYPE = 2;

const formatEmailResponse = (responseData) => {
  return responseData.map((question) => {
    const questionFormat = {};
    questionFormat.otherInformation = question.otherInformation.map((data) =>
      data === " " ? "" : data
    );
    questionFormat.section = question.section.map((data) =>
      data === " " ? "" : data
    );
    questionFormat.content = question.content.map((data) =>
      data === " " ? "" : data
    );
    questionFormat.id = question.id;
    return questionFormat;
  });
};

const formatEmailRequest = (requestData, isRequestFirst = false) => {
  return requestData.map((question) => {
    const questionFormat = {};
    questionFormat.otherInformation = question.otherInformation.map((data) =>
      data === "" ? " " : data
    );
    questionFormat.section = question.section.map((data) =>
      data === "" ? " " : data
    );
    questionFormat.content = question.content.map((data) =>
      data === "" ? " " : data
    );
    if (!isRequestFirst) {
      questionFormat.id = question.id;
    }
    return questionFormat;
  });
};

class EmailService {
  static isOpticianUpdateFirstTime = false;
  static isSalesEmailUpdateFirstTime = false;
  // eslint-disable-next-line no-unused-vars
  getOpticianEmailList(surveyId) {
    return http
      .get(EMAIL_GET_API + OPTICIAN_EMAIL_TYPE + "/" + surveyId)
      .then((response) => {
        if (isEmpty(response.data.result)) {
          EmailService.isOpticianUpdateFirstTime = true;
          return formatEmailResponse(OpticianEmailMock);
        }
        return formatEmailResponse(response.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateOpticianEmail(surveyId, data) {
    const dataRequest = formatEmailRequest(
      data,
      EmailService.isOpticianUpdateFirstTime
    );
    if (!/image\.png$/.test(dataRequest[1].otherInformation[0]) 
      && typeof dataRequest[1].otherInformation[0].name !== 'undefined') {
        return UploadFileService.uploadWithFileNamePrefix(
          dataRequest[1].otherInformation[0],
          "email_image"
        ).then((res) => {
          dataRequest[1].otherInformation[0] = res.data.url;
          return http
            .put(
              EMAIL_UPDATE_API + OPTICIAN_EMAIL_TYPE + "/" + surveyId,
              dataRequest
            )
            .then((response) => {
              EmailService.isOpticianUpdateFirstTime = false;
              return formatEmailResponse(response.data.result);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      return this.updateOpticianDataRequest(surveyId, dataRequest);
    }
  }

  // eslint-disable-next-line no-unused-vars
  getAfterSalesEmailList(surveyId) {
    return http
      .get(EMAIL_GET_API + AFTER_SALES_EMAIL_TYPE + "/" + surveyId)
      .then((response) => {
        if (isEmpty(response.data.result)) {
          EmailService.isSalesEmailUpdateFirstTime = true;
          return formatEmailResponse(AfterSalesEmailMock);
        }
        return formatEmailResponse(response.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateAfterSalesEmail(surveyId, data) {
    const dataRequest = formatEmailRequest(
      data,
      EmailService.isSalesEmailUpdateFirstTime
    );
    if (!/image\.png$/.test(dataRequest[0].otherInformation[1])
      && typeof dataRequest[0].otherInformation[1].name !== 'undefined') {
        return UploadFileService.uploadWithFileNamePrefix(
          dataRequest[0].otherInformation[1],
          "email_image"
        ).then((res) => {
          dataRequest[0].otherInformation[1] = res.data.url;
          return http
            .put(
              EMAIL_UPDATE_API + AFTER_SALES_EMAIL_TYPE + "/" + surveyId,
              dataRequest
            )
            .then((response) => {
              EmailService.isSalesEmailUpdateFirstTime = false;
              return formatEmailResponse(response.data.result);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      return this.updateSaleDataRequest(surveyId, dataRequest);
    }
  }

  updateOpticianDataRequest(surveyId, dataRequest) {
    return http
      .put(
        EMAIL_UPDATE_API + OPTICIAN_EMAIL_TYPE + "/" + surveyId,
        dataRequest
      )
      .then((response) => {
        EmailService.isOpticianUpdateFirstTime = false;
        return formatEmailResponse(response.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateSaleDataRequest(surveyId, dataRequest) {
    return http
    .put(
      EMAIL_UPDATE_API + AFTER_SALES_EMAIL_TYPE + "/" + surveyId,
      dataRequest
    )
    .then((response) => {
      EmailService.isSalesEmailUpdateFirstTime = false;
      return formatEmailResponse(response.data.result);
    })
    .catch((err) => {
      console.error(err);
    });
  }
}

export default new EmailService();
