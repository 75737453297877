const data = [
  {
    id: 1,
    section: ["Call-to-action", "", "Find-a-Store button"],
    content: [
      "Bring your results to your optician!",
      "Present this QR code to your eye care professional. Get expert advice, and lenswear solutions truly designed for your need.",
      "Find a store",
    ],
    otherInformation: [" ", " ", "www.findastore.com"],
  },
  {
    id: 2,
    section: ["Intro"],
    content: [
      "Here is what you you learned about your vision, and the cutting-edge solutions that suit your unique lifestyle!",
    ],
    otherInformation: ["/assets/icons/image.png"],
  },
  {
    id: 4,
    section: ["Recommendation Header", "Description"],
    content: [
      "Your Lens Recommendation",
      "Ask your eye care professional about Nikon's cutting edge lenses and experience vision like never before",
    ],
    otherInformation: [" ", " "],
  },
  {
    id: 5,
    section: ["Social Share"],
    content: ["Share the vision questionnaire with your friends!"],
    otherInformation: [" "],
  },
  {
    id: 6,
    section: ["Disclaimer"],
    content: [
      "Disclaimer. This questionnaire does not replace an eye exam. These recommendation are based on your lifestyle and it does not take into consideration your prescription. Please see you eyecare professional for a complete assessment of your eye health and final lens recommendation.",
    ],
    otherInformation: [" "],
  },
  {
    id: 7,
    section: ["Copyright/datestamp"],
    content: ["@2021 Nikon Optical USA"],
    otherInformation: [" "],
  },
];

export default data;
