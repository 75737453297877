<template>
  <div>
    <confirm-modal ref="confirmModal" />
    <div class="common-department">
      <div :class="getTableHeaderClass + ' action-menu'" :style="{top: headerHeight + 'px'}">
        <div class="user-infor">
          <span>{{ countryAndLanguage }}</span>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light topnav">
          <div id="navbarText" :class="getTableHeaderClass + ' collapse navbar-collapse'">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <router-link
                  exact
                  class="nav-link pr-0 pl-0"
                  to="/email/optician-email"
                  @click.native="changeLink('OpticianEmail')"
                >
                  Find-An-Optician Email
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  exact
                  class="nav-link pr-0 pl-0"
                  to="/email/after-sales-email"
                  @click.native="changeLink('AfterSalesEmail')"
                >
                  After Sales Email
                </router-link>
              </li>
            </ul>
            <!------------- edit range-------------------------->
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <template v-if="!editable">
                  <!-- <img
                  class="pointer"
                  style="height:39px;width:39px;margin-right:10px"
                  src="@/assets/icons/view_unselected.png"
                  @click="clickImage($event)"
                /> -->
                  <b-button
                    variant="outline-info custom-btn"
                    class="btn-blue"
                    @click="turnOnEditable()"
                  >
                    Edit content
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    variant="outline-info custom-btn btn-save"
                    class="btn-blue"
                    @click="saveAll()"
                  >
                    Save
                  </b-button>
                  <b-button
                    variant="outline-info custom-btn"
                    class="btn-blue"
                    @click="turnOffEditable()"
                  >
                    Cancel
                  </b-button>
                </template>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <router-view />
      <!-- <confirm-modal :trigger-modal="isTriggedModal" @agree="isAgree = $event" /> -->
      <!-- Space end page -->
      <div style="height: 50px"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
const OPTICIAN_EMAIL_TYPE = "OpticianEmail";
const AFTER_SALES_EMAIL_TYPE = "AfterSalesEmail";

import _ from "lodash";
import {
addPropertyData, changePropertyData, MODAL_CANCEL, MODAL_SAVE, OK_CONFIRM, reformatData
} from "../../common/CommonUtils";
import EmailService from "../../service/email/EmailService";
import ConfirmModal from "../common/ConfirmModal.vue";

const isOpticianEmailPath = (path) => /\/email\/optician-email\/?/.test(path);

const iAfterSaleEmailPath = (path) =>
  /\/email\/after-sales-email\/?/.test(path);

const isEmailPath = (path) => /\/email\/?$/.test(path);

export default {
  components: {
    ConfirmModal,
  },
  data() {
    const surveyId = this.$store.state.survey.id;
    return {
      surveyId: surveyId <= 0 ? 1 : surveyId,
      type: isOpticianEmailPath(this.$router.currentRoute.path)
        ? OPTICIAN_EMAIL_TYPE
        : AFTER_SALES_EMAIL_TYPE,
      editable: (this.$store.state.survey.isSurveyEditing = false) && false,
    };
  },
  computed: {
    // editable() {
    //   return isOpticianEmailPath(this.$router.currentRoute.path)
    //     ? this.$store.state.email.opticianEmail.editStatus
    //     : this.$store.state.email.afterSalesEmail.editStatus;
    // },
    countryAndLanguage() {
      return this.$store.state.survey.countryAndLanguage;
    },
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.editable
            ? "action-menu-edit"
            : ""
    },
  },
  beforeCreate() {
    const _this = this;
    let surveyId = this.$store.state.survey.id;
    surveyId = surveyId <= 0 ? 1 : surveyId;

    // auto redirect from path /email to /email/optician-email
    if (isEmailPath(this.$router.currentRoute.path)) {
      this.$router.push("/email/optician-email");
    }

    if (isOpticianEmailPath(this.$router.currentRoute.path)) {
      let loader = this.$loading.show();
      EmailService.getOpticianEmailList(surveyId).then((res) => {
        console.log("data", res);
        _this.$store.dispatch("email/initialOpticianEmailData", {
          data: res,
        });
        _this.type = OPTICIAN_EMAIL_TYPE;
        loader.hide();
      });
    } else if (iAfterSaleEmailPath(this.$router.currentRoute.path)) {
      let loader = this.$loading.show();
      EmailService.getAfterSalesEmailList(surveyId).then((res) => {
        _this.$store.dispatch("email/initialAfterSalesEmailData", {
          data: res,
        });
        _this.type = AFTER_SALES_EMAIL_TYPE;
        loader.hide();
        this.$forceUpdate();
      });
    }

    this.$store.commit("email/changeEditStatus", {
      type: OPTICIAN_EMAIL_TYPE,
      data: false,
    });
    this.$store.commit("email/changeEditStatus", {
      type: AFTER_SALES_EMAIL_TYPE,
      data: false,
    });
  },
  methods: {
    changeEditableState(state) {
      this.editable = state;
      this.$store.state.survey.isSurveyEditing = state;
      if (state === true) {
        this.$store.state.survey.editingScreenSaveFunc = this.saveAll;
      }
    },
    clickImage($event) {
      alert("do nothing");
    },
    turnOnEditable() {
      this.changeEditableState(true);
      this.$store.commit("email/changeEditStatus", {
        type: this.type,
        data: true,
      });

      if (this.type === OPTICIAN_EMAIL_TYPE) {
        const opticianEmailData = _.cloneDeep(
          this.$store.state.email.opticianEmail.opticianData
        );
        const editData = changePropertyData(opticianEmailData, {
          propertyName: "isEdit",
          value: true,
        });
        this.$store.commit("email/changeOpticianEmailData", {
          data: editData,
        });
      } else {
        const afterSalesEmailData = _.cloneDeep(
          this.$store.state.email.afterSalesEmail.afterSalesData
        );
        const editData = changePropertyData(afterSalesEmailData, {
          propertyName: "isEdit",
          value: true,
        });

        this.$store.commit("email/changeAfterSalesEmailData", {
          data: editData,
        });
      }
    },

    cancelEdit() {
      if (this.type === OPTICIAN_EMAIL_TYPE) {
        const opticianEmailData = _.cloneDeep(
          this.$store.state.email.opticianEmail.opticianOriginData
        );

        this.$store.commit("email/changeOpticianEmailData", {
          data: addPropertyData(opticianEmailData, {
            propertyName: "isEdit",
            value: false,
          }),
        });
      } else {
        const afterSalesEmailData = _.cloneDeep(
          this.$store.state.email.afterSalesEmail.afterSalesOriginData
        );

        this.$store.commit("email/changeAfterSalesEmailData", {
          data: addPropertyData(afterSalesEmailData, {
            propertyName: "isEdit",
            value: false,
          }),
        });
      }

      this.$store.commit("email/changeEditStatus", {
        type: this.type,
        data: false,
      });
      this.changeEditableState(false);

      this.$forceUpdate();
    },

    turnOffEditable() {
      this.$refs.confirmModal.open(MODAL_CANCEL, (data) => {
        if (data === OK_CONFIRM) {
          this.cancelEdit();
        }
      });
    },

    changeLink(type) {
      this.type = type;
      let loader = this.$loading.show();
      if (type === OPTICIAN_EMAIL_TYPE) {
        EmailService.getOpticianEmailList(this.surveyId).then((res) => {
          this.$store.dispatch("email/initialOpticianEmailData", {
            data: res,
          });
          loader.hide();
        });
      }

      if (type === AFTER_SALES_EMAIL_TYPE) {
        EmailService.getAfterSalesEmailList(this.surveyId).then((res) => {
          this.$store.dispatch("email/initialAfterSalesEmailData", {
            data: res,
          });
          loader.hide();
          window.location.reload();
        });
      }
      loader.hide();
    },

    saveEdit(chainFunc) {
      this.$store.commit("email/changeEditStatus", {
        type: this.type,
        data: false,
      });
      this.changeEditableState(false);
      if (this.type === OPTICIAN_EMAIL_TYPE) {
        const opticianEmailData = _.cloneDeep(
          this.$store.state.email.opticianEmail.opticianData
        );
        const editDataUI = changePropertyData(opticianEmailData, {
          propertyName: "isEdit",
          value: false,
        });
        this.$store.commit("email/changeOpticianEmailData", {
          data: editDataUI,
        });

        const originData = this.$store.state.email.opticianEmail
          .opticianOriginData;
        const getReformatData = reformatData(originData, editDataUI, "data");
        // call api
         let loader = this.$loading.show();
        const response = EmailService.updateOpticianEmail(
          this.surveyId,
          getReformatData
        );
        //update to origin
        response &&
          response.then((res) => {
            if (chainFunc !== undefined)
              chainFunc()
            loader.hide()
            this.$store.dispatch("email/initialOpticianEmailData", {
              data: res,
            });
          }).catch((e) => {
            loader.hide();
            console.error(e);
          });
      } else {
        const afterSalesEmailData = _.cloneDeep(
          this.$store.state.email.afterSalesEmail.afterSalesData
        );
        const editDataUI = changePropertyData(afterSalesEmailData, {
          propertyName: "isEdit",
          value: false,
        });
        this.$store.commit("email/changeAfterSalesEmailData", {
          data: editDataUI,
        });

        const originData = this.$store.state.email.afterSalesEmail
          .afterSalesOriginData;

        const getReformatData = reformatData(originData, editDataUI, "data");
        // call api
        let loader = this.$loading.show();
        const response = EmailService.updateAfterSalesEmail(
          this.surveyId,
          getReformatData
        );
        //update to origin
        response &&
          response.then((res) => {
            if (chainFunc !== undefined)
              chainFunc()
            loader.hide();
            this.$store.dispatch("email/initialAfterSalesEmailData", {
              data: res,
            });
          }).catch((e) => {
            loader.hide();
            console.error(e);
          });
      }
    },
    saveAll(chainFunc) {
      this.$refs.confirmModal.open(MODAL_SAVE, (data) => {
        if (data === OK_CONFIRM) {
          this.saveEdit(chainFunc);
        }
      });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}

@media (min-width: 576px) {
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}
</style>
