const data = [
  {
    id: 10,
    section: ["Header", "Description"],
    content: [
      "",
      "Thank you for choosing Nikon Lenswear solutions. Experience exceptional vision performance with lenses specially designed for you.",
    ],
    otherInformation: ["", "/assets/icons/image.png"],
  },
  {
    id: 20,
    section: ["Sub-heading", ""],
    content: [
      "Your lenses",
      "Experience vision like never before with Nikon's cutting edge lenses.",
    ],
    otherInformation: [""],
  },
];

export default data;
